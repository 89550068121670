import styled from "styled-components";

export const Container = styled.section`
  margin: 100px auto 100px auto;
  max-width: 1200px;
`;

export const Heading = styled.h2`
  margin: 0 0 32px 20px;
  color: #fff;
  font-weight: 700;
  display: inline-block;
  border-bottom: 1px solid #fff;
`;

export const Wrapper = styled.div`
  z-index: 1;
`;

export const Row = styled.div`
  margin: 0 0 40px 0;
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Column1 = styled.div`
  padding: 0 20px;
  grid-area: col1;

  @media screen and (max-width: 768px) {
    padding-bottom: 20px;
  }
`;

export const Column2 = styled.div`
  padding: 0 20px;
  grid-area: col2;
`;

export const ContentText = styled.p`
  margin: 0;
  padding: 0 20px 0 0;
  color: #fff;
  font-weight: 200;
  font-size: 1.25rem;
  line-height: 32px;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
`;

export const ImgWrap = styled.div`
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    margin-top: 30px;
  }
`;
