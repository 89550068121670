import Image from "next/image";
import {
  Container,
  Heading,
  Wrapper,
  Row,
  Column1,
  Column2,
  ContentText,
  ImgWrap,
} from "./InfoElements";
import Avatar from "@/images/avatar.jpg";

const Info = () => {
  return (
    <Container id="info">
      <Heading>About</Heading>
      <Wrapper>
        <Row imgStart>
          <Column1>
            <ContentText>
              Alyssa has been serving the Blind and Visually Impaired community
              for the past 7 years. She completed her undergraduate degree in
              Special Education at Kutztown University of Pennsylvania. She
              taught Braille and communication skills in New Jersey for about 4
              years. She recently graduated from The University of
              Massachusetts, Boston’s Vision Studies Program Masters Degree in
              Orientation and Mobility. She became an ACVREP Certified
              Orientation and Mobility Specialist in 2020.
              <br />
              <br />
              Alyssa currently provides Orientation and Mobility Training to
              ensure safe and independent travel for children ages 3-21 across
              the state of New Jersey and individuals from birth to late
              adulthood in NYC. She has worked in both center-based and
              itinerant settings across five counties in the state NJ and all
              five boroughs in NYC. She has extensive knowledge and experience
              on utilizing public transit safely blind or visually impaired, as
              well as crossing complex intersections in large cities. Alyssa has
              a passion for providing equal access and helping others reach
              their goals in all aspects of their life despite any disabilities.
            </ContentText>
          </Column1>
          <Column2>
            <ImgWrap>
              <Image
                src={Avatar}
                className="image"
                alt="avatar"
                height={650}
                width={560}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
                placeholder="blur"
                blurDataURL="/images/blur.png"
              />
            </ImgWrap>
          </Column2>
        </Row>
      </Wrapper>
    </Container>
  );
};

export default Info;
